(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("tf"));
	else if(typeof define === 'function' && define.amd)
		define(["tf"], factory);
	else if(typeof exports === 'object')
		exports["tfvis"] = factory(require("tf"));
	else
		root["tfvis"] = factory(root["tf"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__84__) {
return 